<template>
  <div class="tub-category-selector">
    <div ref="container" class="container" @scroll="updateScrollDebounced">
      <template v-for="category in categories" :key="category.slug">
        <NuxtLink
          class="category-item"
          :class="{ active: category.slug === activeCategorySlug }"
          no-prefetch
          :to="category.navigation"
        >
          <div class="icon" v-html="category.icon" />
          <span class="title">{{ category.title }}</span>
          <div
            v-if="category.eraseCategoryHandler"
            class="remove-category"
            @click.native="category.eraseCategoryHandler"
          >
            <uikit-close-icon />
          </div>
        </NuxtLink>
        <template v-if="category.slug === 'search'"><div class="separator" /></template>
      </template>
    </div>
    <div v-if="scrollableLeft" class="hider left" />
    <div v-if="scrollableRight" class="hider right" />
    <div v-if="scrollableLeft" class="cta left" @click="scrollLeft">
      <uikit-chevron-icon type="left" />
    </div>
    <div v-if="scrollableRight" class="cta right" @click="scrollRight">
      <uikit-chevron-icon type="right" />
    </div>
  </div>
</template>

<script setup>
import { UikitChevronIcon, UikitCloseIcon } from '@hz/ui-kit'
import { debounce } from '../../helpers/utils'

defineProps({
  categories: {
    type: Array,
    required: true,
  },
  activeCategorySlug: {
    type: String,
    required: true,
  },
})

const container = ref()
const scrollableLeft = ref(false)
const scrollableRight = ref(false)
const updateScroll = () => {
  scrollableLeft.value = container.value.scrollLeft > 0
  scrollableRight.value =
    Math.round(container.value.scrollWidth - container.value.scrollLeft) !== container.value.clientWidth
}
const updateScrollDebounced = debounce(() => updateScroll(), 50)
const scrollLeft = () => {
  container.value.scrollBy({ left: -container.value.clientWidth })
}
const scrollRight = () => {
  container.value.scrollBy({ left: container.value.clientWidth })
}
onMounted(() => {
  const active = container.value.querySelector('.active')
  if (active) {
    container.value.scrollBy({
      left: active.offsetLeft - container.value.clientWidth / 2 + active.clientWidth / 2,
    })
  }
  updateScroll()
})
</script>

<style lang="less" scoped>
.tub-category-selector {
  position: relative;
  display: flex;

  .container {
    display: flex;
    flex-direction: row;
    gap: 32px;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .cta {
    position: absolute;
    width: 12px;
    height: 12px;
    cursor: pointer;
    top: calc(50% - 12px);
    z-index: 1;
    color: var(--ds-color-gray-50);

    svg {
      width: 100%;
      height: 100%;
    }

    &.left {
      left: 0px;
    }

    &.right {
      right: 0px;
    }
  }

  .hider {
    position: absolute;
    top: 0;
    display: block;
    width: 90px;
    height: 100%;
    pointer-events: none;

    &.left {
      left: -18px;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 12.64%, #fff 100%);
    }

    &.right {
      right: -18px;
      background: linear-gradient(270deg, #fff 12.64%, rgba(255, 255, 255, 0) 100%);
    }
  }
}

.separator {
  height: 44px;
  width: 1px;
  align-self: center;
  background-color: var(--ds-color-gray-25);
}

.category-item {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 17px;
  transition: color 0.2s ease;
  cursor: pointer;
  gap: 6px;
  border-bottom: 3px solid transparent;

  .remove-category {
    position: absolute;
    top: 18px;
    right: 4px;
    cursor: pointer;
    display: none;

    :deep(.close-icon) {
      height: 20px;
      width: 20px;

      svg {
        height: 10px;
      }
    }
  }

  &:hover .remove-category {
    display: flex;
  }

  .title {
    color: var(--ds-color-gray-50);
    font-size: 12px;
    font-weight: var(--ds-weight-regular);
    white-space: nowrap;
  }

  .icon {
    height: 20px;
    color: var(--ds-color-gray-50);
  }

  &.active {
    border-bottom-color: var(--ds-color-primary-100);

    .title {
      color: var(--ds-color-primary-100);
      font-weight: var(--ds-weight-semi-bold);
    }

    .icon {
      color: var(--ds-color-primary-100);
    }
  }
}
</style>
